import React,{Component} from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import {Water} from "./libs/Water2"

// import * as dat from 'dat.gui';

/**
 * This is the main rendering component. This component consist of:
 * 1] The basic scene
 * 2] Camera
 * 3] Orbit Controls
 */

//Initialise GUI
// const gui = new dat.GUI();

const params = {
    color: '#ffffff',
    scale: 4,
    flowX: 1,
    flowY: 1
};

const rainCount = 15000;

class ThreeScene extends Component{
    
    //This is a function to handle resizing of window
    handleWindowResize = ()=>{
        // console.log("hello")
        
        //update the camera
        this.camera.aspect = window.innerWidth/window.innerHeight
        this.camera.updateProjectionMatrix()
        // console.log(this.camera.aspect)

        //update the renderer
        this.renderer.setSize(window.innerWidth,window.innerHeight)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        this.renderer.render(this.scene,this.camera)
        // this.canvas = this.renderer.domElement

    }

    // This is a function to display in fullscreen
    handleFullscreen = () => {
    
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement
    
        if(!fullscreenElement)
        {
            if(this.canvas.requestFullscreen)
            {
                try{this.canvas.requestFullscreen()}
               catch(err){
                   console.log(err)
               }
            }
            else if(this.canvas.webkitRequestFullscreen)
            {
                this.canvas.webkitRequestFullscreen()
            }
        }
        else
        {
            if(document.exitFullscreen)
            {
                document.exitFullscreen()
            }
            else if(document.webkitExitFullscreen)
            {
                document.webkitExitFullscreen()
            }
        }
    }

    animation = ()=>{        
        // Update controls
        this.controls.update()

        // Update Rain
        for(let i=0;i<rainCount;i++){
            const i3 = i*3
            this.rainGeo.attributes.position.array[i3+1]-=(0.1+Math.random()*0.1)
            if(this.rainGeo.attributes.position.array[i3+1]<-200){
                this.rainGeo.attributes.position.array[i3+1] = 200
            }
        }
        this.rainGeo.attributes.position.needsUpdate = true
        this.rain.rotation.y +=0.002;

        // Render
        this.renderer.render(this.scene, this.camera)

        requestAnimationFrame(this.animation)
    }

    //Load the entire scene
    init=()=>{
        const overlay = document.getElementById( 'overlay' );
		overlay.remove();

        this.size = {
            width:window.innerWidth,
            height:window.innerHeight
        }
       
        //scene
        this.scene = new THREE.Scene()

        //renderer
        this.renderer = new THREE.WebGLRenderer({
            antialias:true
        })
        this.renderer.setSize(this.size.width,this.size.height)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        this.renderer.toneMapping = THREE.NoToneMapping;

        // Assign the canvas
        this.canvas = this.renderer.domElement
        
        //camera
        this.camera = new THREE.PerspectiveCamera(45,this.size.width/this.size.height,0.1,100)
        this.camera.position.set( 3.91, 1.18, -14 )
        this.camera.lookAt(this.scene.position)
        this.scene.add(this.camera)

        // Controls
        this.controls = new OrbitControls(this.camera, this.canvas)
        this.controls.enableDamping = true
        this.controls.enableZoom = false
        this.controls.enablePan = true

        // create an AudioListener and add it to the camera
        const listener = new THREE.AudioListener();
        this.camera.add( listener );

        // create a global audio source
        const sound = new THREE.Audio( listener );

        // load a sound and set it as the Audio object's buffer
        const audioLoader = new THREE.AudioLoader();
        audioLoader.load( './static/sounds/rain-07.ogg', function( buffer ) {
            sound.setBuffer( buffer );
            sound.setLoop( true );
            sound.setVolume( 0.5 );
            sound.play();
        });

        // ground
        const groundGeometry = new THREE.PlaneGeometry( 200, 200 );
        const groundMaterial = new THREE.MeshStandardMaterial( { roughness: 0.8, metalness: 0.4 } );
        const ground = new THREE.Mesh( groundGeometry, groundMaterial );
        ground.rotation.x = Math.PI * - 0.5;
        this.scene.add( ground );

        const textureLoader = new THREE.TextureLoader();
        textureLoader.load( './static/textures/Halloween_texture.jpg', function ( map ) {

            map.wrapS = THREE.RepeatWrapping;
            map.wrapT = THREE.RepeatWrapping;
            map.anisotropy = 16;
            map.repeat.set( 4, 4 );
            groundMaterial.map = map;
            groundMaterial.needsUpdate = true;

        } );

        // water

        const waterGeometry = new THREE.PlaneGeometry( 200, 200);

         let water = new Water( waterGeometry, {
            color: params.color,
            scale: params.scale,
            flowDirection: new THREE.Vector2( params.flowX, params.flowY ),
            textureWidth: 1024,
            textureHeight: 1024
        } );

        water.position.y = 1;
        water.rotation.x = Math.PI * - 0.5;
        this.scene.add( water );

        
        //Add Ambient light
        const light = new THREE.AmbientLight(0xcccccc,0.4)
        this.scene.add(light)

        // Add directional Light
        const directionalLight = new THREE.DirectionalLight( 0xffffff, 0.6 );
		directionalLight.position.set( - 1, 1, 1 );
        this.scene.add( directionalLight );    

        // Rain
        this.velocity = 0
        this.rainGeo = new THREE.BufferGeometry();
        let rainDrop = []
        for(let i=0;i<rainCount;i++) {
        rainDrop.push( new THREE.Vector3(
            Math.random() * 400 -200,
            Math.random() * 500 - 250,
            Math.random() * 400 - 200
        ));

        this.rainGeo.setFromPoints(rainDrop);
        }

        let rainMaterial = new THREE.PointsMaterial({
            color: 0xaaaaaa,
            size: 0.1,
            transparent: true
          });
        this.rain = new THREE.Points(this.rainGeo,rainMaterial);
        this.scene.add(this.rain);
        
        // skybox

        const cubeTextureLoader = new THREE.CubeTextureLoader();
        cubeTextureLoader.setPath( './static/textures/Cube-Map/' );

        const cubeTexture = cubeTextureLoader.load( [
            'px.png', 'nx.png',
            'py.png', 'ny.png',
            'pz.png', 'nz.png'
        ] );

        this.scene.background = cubeTexture;
        this.scene.fog = new THREE.FogExp2(0x11111f, 0.002);

        //Render scene
        this.renderer.render(this.scene,this.camera)

        //Add event listener for resizing
        window.addEventListener('resize',this.handleWindowResize,false)

        // Add event listener for Fullscreen
        window.addEventListener('dblclick', this.handleFullscreen)


        this.mount.appendChild(this.renderer.domElement)

        this.animation()

    }
    
    render(){
        return(
            <div ref={
                mount=>{this.mount = mount}
            }>
            </div>
        )
    }

    componentDidMount=()=>{
        const startButton = document.getElementById( 'startButton' );
		startButton.addEventListener( 'click', this.init );
    }

}

export default ThreeScene;